.wiki-container {
    display: flex;
    flex-direction: row;
}

.wiki-content-col-1 {
    width: 525px;
    margin-top: 55px;
    top: 50px;
}

.wiki-content-col-2 {
    flex: 1;
    width: 709px;
    padding: 57px 15px 0px 15px;
    /* border: 1px solid rgb(222, 239, 255); */
}

p {
    max-width:100%;
    word-wrap:break-word;
}



.view-blocker-background {
    position: fixed;
    width: 100%;
    top: 45px;
    background:	rgba(192,192,192, 0.8);
    z-index: 99999;
    left: 0px;
}

.wiki-top-fixed-back {
    background: linear-gradient(246.32deg, #127BC2 14.17%, #5E1D9F 100%);
    height: 450px;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
}


.wiki-top-fixed-div {
    /* background: linear-gradient(246.32deg, #127BC2 14.17%, #5E1D9F 100%); */
    height: 400px;
    width: 100%;
    /* position: absolute;
    top: 0px;
    left: 0px; */
    z-index: 0;
    display: flex;
    flex-direction: row;
}

.chck {
    margin-right: 5px;
}

.center-column {
    display: flex;
    justify-content: center;
}


.categories-col {
    background: #FCFBFB;
    width: 30%;
    padding: 10px;
    padding-top: 20px;
    border: 1px solid #ccc;
}

.listing-col {
    width: 70%;
    padding-left:20px;
}

.explainer-video {
   width: 480px;
   height: 270px;
   background: gray;
   z-index: 9999;
   margin-top: 75px;
}

.wiki-articles-box {
    margin-top: 400px;
    background: white;
    width: 100%;
    z-index: 999;
    border-radius: 25px;
    padding: 25px;
    box-shadow: 0 2.8px 2.2px rgba(128,128,128, 0.034), 0 6.7px 5.3px rgba(128,128,128, 0.01), 0 8.5px 10px rgba(128,128,128, 0.06), 0 9.3px 17.9px rgba(128,128,128, 0.07), 0 5.8px 7.4px rgba(128,128,128, 0.086), 0 9px 10px rgba(128,128,128, 0.12);
}

.wiki-search {
    width: 80%;
    margin-bottom: 20px;
}

.cat-row {
    margin-left: 14px;
    margin-right: 14px;
}

.wiki-articles-header {
    font-size: 28px;
    margin-right: 50px;
    width: 50%;
}

.category-display-row {
    color: 333333;
    display: flex;
    padding-bottom: 7px;
    margin-bottom: 7px;
}

.article-display-row:hover {
    background-color: #ccc;
    cursor: pointer;
}

.article-display-row {
    color: 333333;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #CCCCCC;
    padding: 3px;
    padding-top: 7px;

    padding-bottom: 7px;
    margin-bottom: 7px;
}

.wiki-articles-top-row {
    display: flex;
    flex-direction: row;
}

.recordings-present {
    font-size: 18px;
    color: #333333
}

.no-recordings {
    font-style: italic;
    color: #127BC2;
}

.wiki-header {
    border-bottom: 1px solid #CCCCCC;
    margin-bottom: 15px;
    margin-top: 15px;
    padding-bottom: 10px;
    font-weight: 600;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;

}

.wiki-display-col-1 {
    width: 40%;
    display: flex;
    align-items: center;
}

.wiki-display-col-2 {
    width: 25%;
    display: flex;
    align-items: center;
}

.wiki-display-col-3 {
    width: 20%;
    display: flex;
    align-items: center;
}

.wiki-display-col-4 {
    width: 15%;
    display: flex;
    align-items: center;
}

.featured-lbl {
    margin-bottom: 10px;
    font-weight: 600;
}

.suggested-list {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
  }

  .center-wrapper-wiki {
      /* margin-top: 400px; */
      margin-bottom: 100px;
  }

  .main-content {
    text-align: left;
    width: 100%;
    font-size: 18px;
  }
  
  
  .suggested-list-item:hover {
    background: #F5FDFF;
    cursor: pointer;
  }
  
  .suggested-list-item {
    padding: 12px 0px;
    color: #333333;
    border-bottom: 1px solid #CCCCCC;
    margin-bottom: 10px;
    margin-right: 30px;
    width: 350px;
    display: flex;
    text-align: left;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  

.explainer-header {
    height: 269px;
    background: transparent;
    z-index: 9999;
    margin-left: 100px;
    margin-top: 100px;
    color: white;
}

.explainer-header-title {
    font-size: 50px;
    margin-bottom: 20px;
    text-align: center;
}

.explainer-header-description {
    font-size: 20px;
}

.view-blocker-modal {
    position: fixed;
    margin: 0 auto;
    top: 200px;
    left: 40%;
    z-index: 9999;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 200px;
    background: white;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 200px;
    padding: 50px;
    box-shadow: 0 2.8px 2.2px rgba(128,128,128, 0.034), 0 6.7px 5.3px rgba(128,128,128, 0.01), 0 8.5px 10px rgba(128,128,128, 0.06), 0 9.3px 17.9px rgba(128,128,128, 0.07), 0 5.8px 7.4px rgba(128,128,128, 0.086), 0 9px 10px rgba(128,128,128, 0.12);
}

.wiki-content-top-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.article-title {
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    color: #333333;
}

.re-record-text {
    margin-right: 10px;
    color:  #666666;
}

.expand-wiki {
    color: #666666;
    font-size: 16px;
    margin-left: 5px;
}

.wiki-hider-cont {
    /* position: absolute;
    top: 15px;
    right: 15px; */
    cursor: pointer;
    margin-bottom: 40px;
    height: 40px;
    line-height: 40px;
    text-align: left;
}

.wiki-content-col-3 {
    width: 30%;
    position: relative;
}

.wiki-content-box {
    padding: 5px;
    margin-top: 10px;
    background: #f9f9f9;
    border: 1px solid  #666666;
}

.recording-indicator {
    color: #0EBE0E;
    font-style: italic;
    font-size: 12px;
}

.recordings-count {
    color: #697777;
    font-family: Segoe UI;
font-style: normal;
font-weight: normal;
font-size: 15px;
line-height: 18px;
}

.article-body {
    border-left: 1px solid #cccccc;
    padding: 0px 15px 80px 15px;
    margin-top: 10px;
}

.recordings-count-icon {
    margin-bottom: 5px;
    margin-left: 15px;
    color: #0ebe0e;
}

.recordings-count-label {
    margin-left: 10px;
    font-size: 15px;
    font-style: italic;
    color: #0ebe0e;
}

.article-label {
    font-family: Segoe UI;
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 22px;
color: black;
margin-right: 5px;
}

.article-url {
    font-size: 15px;
    font-weight: 400;
    color: rgb(16, 153, 252) !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
    color: #0EBE0E !important;
}

/* .MuiSwitch-thumb {
    color: #0EBE0E;
} */

/* .MuiSwitch-switchBase {
    color: gold !important;
} */

/* .MuiSwitch-colorSecondary.Mui-checked {
    background-color: blue;
} */

/* .MuiSwitch-colorSecondary.Mui-checked {
    color: green;
} */

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: #90f390 !important;
}

/* .MuiSwitch-track {
    background-color: #90f390 !important;
} */


.wiki-select-row {
    padding: 15px 0px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
}

.switch {
    color:  #666666;
}

.mode-toggle {
    font-size: 16px;
    margin-left: 2px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0.00938em;
    margin-right: 20px;

}

.wiki-flag {
    position: absolute;
    right: 20px;
    top: 5px;
}


.mode-toggle.play {

}

.video-listing-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #CCCCCC;
    border-radius: 0px;
}

.video-listing-item {
    margin-right: 10px;
}