.videoBox {
    /* width: 535px; */
    min-height: 400px;
    border: 1px solid  #666666;
    background: #E2E0E0;
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.videoBox.listing {
    background: white;
    overflow-y: auto;
}

.player-recorder {
    width: 100%;
}

.recording-time-indicator {
    position: absolute;
    bottom: 30px;
    color: red;
    font-size: 30px;
    font-weight: 600;
    left: 45px;

}

.recording-time-indicator-r {
    position: absolute;
    bottom: 10px;
    color: red;
    font-size: 20px;
    font-weight: 600;
    left: 110px;
    z-index: 10;

}

.transition-counter {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9999;
    transform: translate(-50%, -50%);
    color: #9BD5FB;
    font-weight: bold;
    font-size: 6em;
}

.video-controls-cont {
    background: white;
    padding: 10px;
    border-bottom: 1px solid #666666;
    border-left: 1px solid #666666;
    border-right: 1px solid #666666;
}

.video-controls-box {
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: row;
    padding: 7px;
    border-bottom: 1px solid #cccccc;;
}

.control {
    color:  #666666;
    border: 1px solid  #666666;
}

.control:hover {
    cursor: pointer;
    border: 1px solid dark #666666;
    color: dark #666666;
}

.txt-control {
    color:  #666666;
}

.txt-control:hover {
    cursor: pointer;
    color: dark #666666;
}

.video-container {
    width: inherit;
    height: inherit;
    /* margin-bottom: - 50px; */
    /* margin-top: -27px; */
}

.video-container.msg-rc {
    padding: 50px;
    font-size: 20px;
    text-align: center;
    
}

.mini-video-list-toggle {
    display: flex;
    height: 100%;
    margin-top: 50px;
    flex-wrap: wrap;
    flex-direction: row;
}

.video-grid-item {
    width: 250px;
}

.video-grid {
    display: flex;
    flex-direction: row;
}

.clicker {
    cursor: pointer;
    text-align: center;
    width: 250px !important;
}

.btn-approval-row {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.clicker:hover {
    background: #9BD5FB;
}

.video-transition-overlay {
    z-index: 999;
    position: absolute;
    font-family: sans-serif;
    background: white;
    display: flex;
    justify-content: center;
    padding: 10px;
    align-items: center;
    border-radius: 10px;
    flex-direction: column;
    height: 250px;
    width: 250px;
    border: 1px solid gray;
}

.record-message {
    color: white;
    background: #0EBE0E;
    padding: 15px;
    border-radius: 5px;
}


.record-again-btn-vocab {
    height: 30px !important;
    border: 1px solid gray;
    padding: 0px 28px 0px 20px;
    border-radius: 10px;
    background: #7A22D1;
    color: white;
    cursor: pointer;
}

.record-again-btn {
    height: 35px !important;
    border: 1px solid gray;
    padding: 0px 28px 0px 20px;
    border-radius: 10px;
    background: #7A22D1;
    color: white;
    cursor: pointer;
}

.video-progress-bar {
    margin-top: -7px;
    margin-bottom: 0px;
}

.record-again-btn:hover {
    background: #7A22D1;
}

.money {
    color: gold;
}

.overlay-row {
    margin-bottom: 7px;
}

.please-select {
    background: rgb(16, 153, 252);
    border-radius: 10px;
    padding: 15px;
    color: white;
}

.video-confirm-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.video-confirm-row:hover {
    cursor: pointer
}

.video-confirm-col {
    color:  #666666;
    font-size: 16px;
    width: 50%;
    font-weight: bold;
    text-align: center;
}

.available-videos-header {
    font-size: 15px;
    color:  black;
    border-bottom: 1px solid  #cccccc;
    margin-bottom: 10px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 20px 0px 0px;
}

.video-select-item {
    padding: 7px;
    border-radius: 10px;
}

.video-select-item:hover {
    padding: 7px;
}

.video-select-item:hover {
    background: #b8dde9;
    cursor: pointer;
}

/* .user-name {
    font-weight: bold;
} */

.available-video-row {
    width: 100%;
}