.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.block-ui-container {
  position: absolute;
  z-index: 1010;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  min-height: 2em;
  cursor: not-allowed !important;
  overflow: hidden;
}

.App-link {
  color: #61dafb;
}

.aria-modal{
  background-color: white;
  width: 400px;
  padding: 10px;
  border-radius: 5px;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.aria-modal {
  padding:15px;
}

/* GLOBAL COLORS  */

.c-blue {
  color: #1099FC;
}

.b-blue {
  background: #1099FC;
}

.c-dgray {
  color: #333333
}

.b-dgray {
  background: #333333
}

.c-green {
  color: #0EBE0E;
}

.b-green {
  color: #0EBE0E;
}

.c-orange {
  color: #127BC2;
}

body {
  position: relative;
}

.b-purple {
  background: #7A22D1;
}

.b-orange {
  background: #127BC2;
}

.c-ngray {
  color: #CCCCCC
}

.b-ngray {
  color: #CCCCCC
}

.c-gray {
  color: #666666;
}

.b-gray {
  background: #666666;
}

.b-white {
  background: white;
}

.c-white {
  color: white;
}

.icon {
  border: unset;
}

.nav-name {
  border-right: 1px solid #CCCCCC;
  padding-right: 25px;
  font-size: 15px;
}

.trophy {
  color: #FAB500;
  margin-bottom: 8px;
}


.pinline {
  display:inline;
  word-wrap: break-word;
}

/* .anchorHover:hover {
  color: #5E1D9F;
  text-decoration: underline;
} */

/* a:not([href]):hover { */
.anchorHover:hover {
  color: #5E1D9F !important;
  text-decoration: underline !important;
  font-weight: bold;
}

.editHoverIcon:hover {
  color: green !important;
  border: 2px solid green;
  padding: 5px;
  border-radius: 15px;
}

.paddingDiv {
  padding-bottom:40px !important;
}

.explore-pb-2 {
  padding-bottom:2.5rem !important;
}

.paddingDivBottom {
  padding-bottom:60px !important;
}

.toolTipPosition {
  left: 844px !important;
  top: 175px !important;
}

#userProfileMenu {
  border: 1px solid #ccc !important
}

.video-container-r {
  width: 450px;
  margin: 0 auto;
}

.video-r {
  width: 450px;
  margin: 0 auto;
}

.flex-row-center {
  display: flex;
  justify-content: center;
}

.center-box {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.center-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

p {
  margin: unset;
}

.wiki-return {
  color: white;
  border-radius: 15px;
  border: 0px solid white;
  background: #666666;
  padding: 2px 15px 2px 5px;
  width: 125px;
}

.login-btn {
  border: 1px solid #127BC2;
  color: #127BC2;
  padding: 2px 12px 2px 12px;
  display: flex;
  align-items: center;
  height: 30px;
  position: absolute;
  right: 0px;
}

.blocker-text {
  font-size: 15px;
  text-align: center;
}

.close-modal {
  position: absolute;
  top: 15px;
  right: 15px;
}

.login-btn.bmodal {
  position: unset;
  width: 75px;
  margin-top: 25px;
}

.signin-cont {
  display: flex;
  position: fixed;
  top: 0px;
  height: 100%;
  left: 0px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(246.32deg, #127BC2 14.17%, #5E1D9F 100%);
}

.my-facebook-button-class {
  position: absolute;
    left: 0px;
    width: 100%;
    height: 70px;
    background: transparent;
    color: white;
    opacity: 0;
}

.b-white {
  background: white !important;
}

.signin-item {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.login-btn:hover {
  background: #127BC2;
  color: white;
  cursor: pointer;
}

.Dropdown-control:hover {
  box-shadow: none;
}

.sub-directory {
  height: 15px;

}

.Dropdown-control {
  border: unset !important;
  line-height: 15px;
}

.Dropdown-menu {
  overflow: hidden !important;
}

.nav-dropdown-cont {
 margin-top: 2px;
 margin-left: 50px;
 padding-bottom: 5px;
 width: 170px;
}

.container {
  margin-top: 53px;
  background: white;
  min-height: 1100px;
}

.explainer-message {
  text-decoration: underline;
  font-style: italic;
  color:  #666666;
  width: 60%;
}

.title-text {
  margin-bottom: 10px;
}

.info-title-box {
  background: linear-gradient(246.32deg, #127BC2 14.17%, #5E1D9F 100%);
  height: 300px;
  border-left: 2px solid #F2F2F2;
  width: 100%;
  color: white;
  padding: 50px 50px 100px 120px; 
  z-index: 1;
}

.explanation-text {
  font-size: 15px;
  color: #333333;
}

.info-title-main {
  font-size: 50px;
  min-width: 500px;
}

.videoplaceholder.info {
  margin: 0px;
  max-width: 700px;
}

.info-p {
  margin-top: 25px;
}
.info-col-1 {
  padding: 10px;
  margin-right: 10px;
}


.info-col-2 {
  padding: 10px;
}

.how-it-works-tbl {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.people-grid {
  display: flex; flex-direction: row;
  flex-wrap: wrap;
}

.check-mark {
  font-size: 40;
}

.i-profile-photo {
  border-radius: 50%;
  margin-bottom: 10px;
  width: 75px;
}

.job-title-label {
  font-size: 15px;
  color: #333333;
  text-align: center;
}

.name-label {
  color: #333333;
  font-size: 18px;
}

.msft-label {
  color: #697777;
  font-size: 15px;
  text-align: center;
}

.people-item {
  width: 250px;
  height: 250px;
  background: #FCFBFB;
  display: flex;
  align-items: center;
  padding: 25px;
  padding-top: 50px;
  flex-direction: column;
  border-bottom: 1px solid #CCCCCC;
  margin-bottom: 15px;
  margin-right: 15px;
}

.our-team-box {
  z-index: 2;
  width: 1200px;
  background: transparent;
  padding-left: 50px;
  margin-top: 50px;
}

.explanation-box {
  z-index: 2;
  background: transparent;
  border-left: 2px solid #F2F2F2;
  padding-left: 50px;
  margin-top: -70px;
}

.info-section-header {
  font-size: 36px;
  color: #000000;
  margin-bottom: 25px;
}

.info-container {
  height: 4100px;
  margin-bottom: 45px;
}

.how-it-works-box {
  margin-left: 150px;
  margin-top: 50px;
  width: 800px;
  background: transparent;
  padding-left: 50px;
}

/* video {
  -webkit-transform:rotateY(180deg);
} */

/* @-moz-document url-prefix() {

  video {
    -webkit-transform: unset;
  }
} */

.info-title-secondary {
  font-size: 15px;
  margin-top: 20px;
  min-width: 500px;
}

.video-warning {
  color: white;
  background: red;
  opacity: 0.5;
  padding: 5px;
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 10px;
  z-index: 1;
}

.cancel-btn {
  background:  red !important;
}

.flag {
  color: "#ccc";
  cursor: pointer;
}

.confirm-btn.skip {
  background: white;
  color: #7A22D1;
  font-weight: 600;
  border: 1px solid #F0F0F0 !important;
}

.modal-close-x {
  position: absolute;
  right: 10px;
  top: 2px;
}

.modal-separator {
  margin-top: 25px;
}

@font-face {  
  font-family: 'Segoe UI'; 
  font-weight: 400;
  font-style: normal;
  font-display: swap; 
 unicode-range: U+000-5FF;
   src: 
      local("Segoe UI Light"),
      url('/src/latest.woff2') format('woff2')
}

.confirm-btn.skip.r {
  border: 1px solid #ccc !important;
}

.ReactModal__Content.ReactModal__Content--after-open {
  z-index: 999999;
}

.login-prompt {
  height: 600px;
  padding-top: 200px;
  text-align: center;
}

.account-create-redirect {
  height: 400px;
  margin-top: 75px;
  text-align: center;
  padding: 50px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.record-btn.special-signup-link-btn {
  height: 25px;
  margin-top: 10px;
  cursor: pointer;
  width: 300px;
}

.special-signup-link {
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 25px;
}

.signup-warning {
  font-size: 20px;
  color: #333333;
  margin-bottom: 25px;
}

.login-prompt.consent {
  padding-top: 50px;
}

.login-prompt.account-creation {
  height: 1400px;
  padding-top: 0px;
  text-align: center;
}

.accept-reject-consent-box {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 250px;
  justify-content: center;
  border-radius: 10px;
}

.confirm-btn {
    width: 80px;
    height: 35px;
    /* background: #6E6E6E; */
    border-radius: 30px;
    align-items: center;
    padding: 5px 20px;
    color: white;
    cursor: pointer;
    margin-left: 15px;
    background: #7A22D1;
    border: none !important;
}

.confirm-selection-btn.yes {
  background:   #0EBE0E;
}

.confirm-selection-btn.no {
  background:   red;
}


.noListStyle {
  list-style: none;
  margin-left: -20px;
  
}

.w-25 {
 width: 25%;
}

.w-20 {
 width: 20%;
}

.w-15 {
 width: 15%;
}

.w-40 {
  width: 40%;
}

.w-35 {
  width: 35%;
}


.w-10 {
  width: 10%;
}

.dashboard-search {
  min-width: 250px;
  border: 1px solid #cccccc;
  padding: 2px 7px 2px 7px;
  border-radius: 7px;
  height: 36px;
}

.unrecorded {
  background: #add8e6;
}

.clear-search-btn {
  position: absolute;
  right: 10px;
  top: 5px;
  z-index: 999999;
  background: white;
  cursor: pointer;
}


.google-btn {
  margin: 5px;
  width: 80%;
  padding: 4px;
  max-width: 350px;
  height: 50px;
  background-color: #fcfcfc;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .2);
  cursor: pointer;
  cursor: hand;
  align-self: center;
  user-select: none;
  transition: all 400ms ease 0s;
  display: flex;
}
.google-btn .google-icon-wrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  user-select: none
}
.google-btn .google-icon-svg {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
  user-select: none
}
.google-btn .btn-text {
  float: right;
  margin: 4px 14px 40px 40px;
  color: #757575;
  font-size: 19px;
  letter-spacing: .2px;
  user-select: none
}
.google-btn:hover {
  box-shadow: 0 3px 8px rgba(117, 117, 117, .5);
  user-select: none
}
.google-btn:active {
  box-shadow: 0 1px 1px #757575;
  background: #F8F8F8;
  color: #fff;
  user-select: none
}

.labelEnglishInfo {
  margin-bottom: 10px;
  margin-left: 5px;
  font-size: large;
}

hr {
  margin:0.75rem -1rem !important;
  border-top:1px solid black;
}

.displayBlock {
  display:inline-block !important; 

}

.toggle-btn {
  cursor: pointer;
}

.explainer-video-cont {
  height: 250px;
  width: 400px;
  border-radius: 10px;
  margin-right: 25px;
}

.record-btn {
  padding: 0px 12px;
  background:  #7A22D1;
  color: white;
  width: 130px;
  height: 30px;
  border-radius: 180px;
  border: none;
  cursor: pointer;
}

.r-controls-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  align-items: center;
  height: 100%;
}

.video-background {
  background: #697777;
  border: 1px solid #697777;
  max-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.seed-header {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.seed-top-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.video-background-r {
  background: #697777;
  min-height: 300px;
  width: 650px;
  position: relative;
}

.seed-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.record-btn-r {
  position: absolute;
  right: 10px;
  width: 125px;
  top: 7px;
  height: 40px !important;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.record-btn-r.stop {
  margin-bottom: 5px;
}

.show-label-cont {
  margin-top: 1px;
}

.record-btn-r.submit {
  right: 10px;
  width: 240px;
}

.show-label {
  color: #127BC2;
  cursor: pointer;
}

.video-bottom-dv-r {
  width: 650px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  margin-bottom: 30px;
  padding: 0px 20px;
  background: #FCFBFB;
  box-shadow: 0 2.8px 2.2px rgba(128,128,128, 0.034), 0 6.7px 5.3px rgba(128,128,128, 0.01), 0 8.5px 10px rgba(128,128,128, 0.06), 0 9.3px 17.9px rgba(128,128,128, 0.07), 0 5.8px 7.4px rgba(128,128,128, 0.086), 0 9px 10px rgba(128,128,128, 0.12);
}

.record-buttons {
  position: absolute;
  right: 10px;
}


.video-bottom-dv-v {
  display: flex;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  border-bottom: 1px solid #ccc;
  padding: 10px 20px;
  background: #FCFBFB;
}

.video-bottom-dv {
  display: flex;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 10px 20px;
  background: #FCFBFB;
  box-shadow: 0 2.8px 2.2px rgba(128,128,128, 0.034), 0 6.7px 5.3px rgba(128,128,128, 0.01), 0 8.5px 10px rgba(128,128,128, 0.06), 0 9.3px 17.9px rgba(128,128,128, 0.07), 0 5.8px 7.4px rgba(128,128,128, 0.086), 0 9px 10px rgba(128,128,128, 0.12);
}

.reveal-word-display {
  color: #333333;
    font-weight: 600;
    font-size: 20px;
}

.modal-content {
  top: 50px;
}

.inner-record-btn-label {
  margin-left: 5px;
}

.modal-font {
  color: #333333;
}

.record-modal-header {
  color: #333333;
}

.record-modal {
  top: 50px;
}

.homepage-btns-container {
 display: flex;
 flex-direction: row;
 justify-content: center;
 color: white;
}

.homepage-big-title {
  font-size: 50px;
  line-height: 58px;
  /* or 116% */

  align-items: center;
  text-align: center;

  /* Neutral / White */

  color: #FFFFFF;
  /* DP 5 */

  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  margin-bottom: 15px;
}

.homepage-subtitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  text-align: center;
  letter-spacing: -0.24px;

  /* Neutral / White */

  color: #FFFFFF;
  /* DP 5 */

  text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

.homepage-btn-description {
  font-weight: 600;
  font-size: 18px;
}

.centr {
  text-align: center;
}

.homepage-btn {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100px;
  color: white;
  border: none;
  padding: 10px 20px 10px 20px;
  margin-top: 20px;
  cursor: pointer;
}

.homepage-btns-col {
  width: 33%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.logged-in-widget-cont {
  height: 150px;
  display: flex;
}

.logged-in-widget-cont.user-profile {
  margin-top: 75px;
  margin-bottom: 10px;
}

.widget-title {
  width: 31%;
  background: #5E1D9F;
  padding: 15px;
  color: white;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.welcome-user-txt {
  font-size: 20px;
}

.welcome-user-subtxt {
  font-size: 15px;
}

.widget-body {
  width: 80%;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #F2F2F2;
}

.btn-container {
  display: flex;
  flex-direction: row;
}

.rank-col {
  margin-left: 15px;
  margin-right: 15px;
}

.big-verified-count {
  font-size: 36px;
  color: black;
}

.widget-row {
  margin-top: 9px;
}

.widget-level-label {
  font-weight: 600;
  font-size: 15px;
  margin-right: 10px;
  color: black;
}

.widget-level-txt {
  font-size: 15px;
  color: #666666;
}

.back-div {
  background: linear-gradient(180deg, #7A22D1 45.25%, #4D2873 100%);
  height: 570px;
  display: flex;
  padding-top: 25px;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
}

.back-div-inner {
  width: 1200px;
}

.lower-col-1 {
 width: 55%;
}

.lower-col-2 {
 width: 20%;
 display: flex;
 justify-content: flex-start;
 align-items: center;
 flex-direction: row;
}

.lower-col-3 {
 width: 25%;
 display: flex;
 justify-content: space-between;
 align-items: center;
 flex-direction: row;
}

.leaderboard-header {
  font-weight: 600;
  font-size: 15px;
  padding: 15px;
  border-bottom: 1px solid #CCCCCC;
}

.left-col-description {
  position: absolute;
  left: 20px;
  top: 250px;
  width: 250px;
  text-align: left;
  margin-right: 25px;
}

.description-second {
  color: #333333;
}

.search-and-seed {
  display: flex;
  flex-direction: row;
  margin: 0px 0px 10px 0px;
}

.new-seed-message {
  margin-right: 10px;
  color: white;
  font-size: 15px;
  line-height: 37px;
  display: flex;
    align-items: center;
}

.seed-btn {
  margin-right: 10px;
  width: 200px !important;
  margin-right: 0px !important;
  border: 1px solid white;
}

.central-cols {
  display: flex;
  flex-direction: row;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border: 1px solid #CCCCCC !important;
  border-radius: 5px;
}

.select-search:not(.is-disabled) .select-search__input  {
  border: 1px solid #CCCCCC;
  border-radius: 5px;
}

.select-search:not(.is-disabled) .select-search__input:hover  {
  border: 1px solid #CCCCCC;
  border-radius: 5px;
}


.home-box {
    background: white;
    margin-top: -100px;
    margin-bottom: 50px;
    border: 1px solid white;
   border-radius: 30px;
  padding: 40px;
  box-shadow: 0 2.8px 2.2px rgba(128,128,128, 0.034), 0 6.7px 5.3px rgba(128,128,128, 0.01), 0 8.5px 10px rgba(128,128,128, 0.06), 0 9.3px 17.9px rgba(128,128,128, 0.07), 0 5.8px 7.4px rgba(128,128,128, 0.086), 0 9px 10px rgba(128,128,128, 0.12);
}

.footer-link {
  cursor: pointer;
  color: #666666 !important;
}

.contr {
  background: rgb(94, 29, 159);
  bottom: 110px;
  position: absolute;
  left: 50%;
  width: 250px;
  margin-left: -75px;
  cursor: pointer;

}

.fstcol-1 {
  margin-top: 15px;
  overflow-y: auto;
}

.fstcol-2 {
  background: white;
  padding-left: 20px;
  padding-top: 25px;
}

.nav-wrapper {
    position: relative;
    display: flex;
    width: 1200px;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}

.purpose-header {
  margin-bottom: 50px;
  font-size: 28px;
}

.show-label-cont {
  display: flex;
  align-items: center;
}

.main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.recorder-extension-body {
  height: 500px;
}

.inner-container {
  width: 1200px;
  min-height: 1100px;
  margin: 0 auto;
}

.purpose-txt {
  margin-bottom: 50px;
  font-size: 15px;
}

.home-bottom {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 90%;
  
}

.explainer-text-cont {
  height : 400px;
  width: 450px;
}

.sign-up-style {
  background: white;
  border-radius: 30px;
  color: black;
  font-size: 16px;
  padding: 10px 15px;
}

.email-entry-inpt {
  width: 400px;
  padding: 10px;
  border-radius: 5px;
  margin-right: 25px;
}

.stay-in-touch {
  background: #5E1D9F;
  padding: 20px 0px 100px 110px;

  width: 100%;
  color: white;
}

#root {
  min-height: 100vh;
}

.footer-row {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin: 0 auto;
  background: white;
}

.footer {
  z-index: 9999;
  background: white;
  padding: 15px 0px;
  color: #666666;
  width: 100%;
  display: flex;
  flex-direction: row;
  left: 0px;
  width: 1200px;
  margin: 0 auto;
}

.footer-item {
  margin-right: 30px;
  cursor: pointer;
}

.leaderboard-title {
  color: black;
  font-size: 28px;
  margin-bottom: 20px;
}




.disappear-message {
  color: red;
  margin-left: 15px;
}




/**
 * Main wrapper
 */
 .select-search {
  width: 100%;
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
* Input
*/
.select-search__input {
  display: block;
  height: 30px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
  padding-left: 40px;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #fff;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
  list-style: none;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #2FCC8B;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #2eb378;
  color: #fff;
}

/**
* Group
*/
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2FCC8B;
}


.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

.flx-row {
  display: flex;
  flex-direction: row;
}

.flx-v-25-empty {
  height: 25%;
}

.cntr {
  text-align: center;
}

.no-header-route-top {
  margin-top: 30px;
}

.two-column-seed-factory {
  display: flex;
  flex-direction: row;
}

div.react-datepicker-wrapper input {
  margin-left: 0px !important;
  width: 100% !important;
}

.save-btn-reg {
  opacity: 0.85;
  cursor: pointer;
}


.save-btn-reg:hover {
  opacity: 1.0
}

.registration-form-cont {
  z-index: 333333;
  background: white;
  width: 80%;
  height: fit-content;
  padding: 50px;
  border-radius: 15px;
  position: absolute;
  top: 100px;
}

.registration-form-background {
  background: #333333;
  z-index: 222222;
  position: absolute;
  left: 0px;
  right: 0px;
  height: 1500px;
  min-height: -webkit-fill-available;
  width: 100%;
  margin-top: -100px;
  display: flex;
  align-items: center;
  justify-content: center;
}