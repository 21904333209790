
.c-text-primary {
    color: white;
    font-size: 50px;
}

.c-text-secondary {
    color: #666666;
    width: 20%;
    color: white;
}