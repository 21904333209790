.submitted-video-horizontal-list {
    display: flex;
    flex-direction: row;
    width: 90%;

}

.horizontal-video {
    height: 188px;
    padding: 0px 15px 0px 15px;
}

.letter-item {
    margin-left: 8px;
    margin-right: 8px;
    cursor: pointer;
}

.explore-profile-name {
  text-align: center;
  position: relative;
}


.explore-video-listing {
    margin-top: 20px;
    width: 80%;
    overflow: auto;
  }
  
  .grouped-video-row {
    padding-bottom: 1px;
    margin-top: 3px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    min-height: 260px;
  }
  
  .full-grouped-row {
    display: flex;
    flex-direction: row;
  }

  .explore-video-description {
    min-width: 155px;
    background: rgb(242, 242, 242);
    padding: 10px;
    box-sizing: content-box;
    border-radius: 10px 0px 0px 10px;
    border-left: 1px solid lightgray;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
  }

  .model-des {
    background: rgb(242, 242, 242);
    border-radius: 0px 10px 10px 10px;
    padding-right: 15px;
    padding-top: 10px;
    border-right: 1px solid lightgray;
    margin-right: 15px;
    border-right: 1px solid lightgray;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    position: relative;
  }

  .video-name {
      color: #333333;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
  }

  .explore-flag {
    position: absolute;
    top: -3px;
    right: 15px;
  }

  .explore-flag.model {
    bottom: 5px;
    top: unset;
    right: 30px;
  }

  .record-btn-row {
      padding-top: 10px;
  }

  .explore-alphabet {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    position: relative;
  
  }
  
  .video-count-row {
    line-height: 30px;
    margin-left: 15px;
  }
  
  .explore-letters {
    line-height: 30px;
    margin-left: 7px;
    color: #666666;

  }
  
  .video-count-style {
    color: #666666;
  }

  .recording-count {
      color: #666666;
      margin-top: 12px;
  }

  .record-label {
    margin-left: 5px;
  }