.verified-count {

}

.verified-text {
    margin-right: 7px;
    margin-bottom: 4px;
}

.video-side-by-side-cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.verify-col-left {
    width: 50%;
}

.verify-flag {
    position: absolute;
    top: -2px;
    left: 110px;
}
.verify-flag.comm {
    position: absolute;
    top: -2px;
    left: 150px;
}

.carousel {
    height: 40px;
    border: 1px solid #F2F2F2;
    background: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
}

.name-cont {
    margin-left: 6px;
}

.carousel-right-boundary {
    background: white;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    position: absolute;
    right: 0px;
    top: 0px;
    margin-left: 10px;
    border-left: 1px solid #F2F2F2;
}

.carousel-column {
    padding: 10px 20px 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.col-first-c {
    background: #F2F2F2;
    padding: 10px 20px 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.ci {
    border-radius: 5px;
    width: max-content;
    padding: 5px 30px 5px 30px;
    font-size: 13px;
    align-items: center;
    display: flex;
    height: 30px;
}

.carousel-item-rest {
    background: #F2F2F2;
    color: #333333;
}

.carousel-item-first {
    background: #697777;
    color: white;
}

.verify-col-right {
    width: 50%;
}

.verify-video {
    width: 100% !important;
    display: block;
    height: 300px;
}

.verify-video.r {
    border-left: 1px solid #333333;
    width: 100% !important;
}

.verify-video.l {
    border-right: 1px solid #333333;
    width: 100% !important;
}

.video-background-v-left {
    background:#697777;
    max-height: 300px;
    display: flex;
    justify-content: center;
}

.video-background-v-right {
    background:#697777;
    max-height: 300px;
    display: flex;
    justify-content: center;
}

.space-three {
    justify-content: space-between;
}


.flag {
    color: red !important
  }

.video-sign {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
    margin-right : 40px;
}

.verify-portal-cont {
    height: 70px;
    background: 	#F0F0F0;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* display: flex;
    justify-content: center;
    flex-direction: column; */
}

.portal-top-row {
    height: 40px;
    padding: 25px 0px 5px 0px;
    width: 90%;
    margin: 0 auto;
    border-bottom: 1px solid #DDD;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
    position: relative;
}

.explainer-text-portal {
    font-weight: normal;
    font-size: 16px;
    color: #333333;
}

.portal-response-row {
    text-align: center;
    margin-top: 20px;
}

.space-right {
    margin-right: 15px;
}

.flag-and-info {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}