.video-section-title {
    text-align: center;
    background: #cccccc;
    font-size: 18px;
    padding: 7px;
    font-weight: 600;
    margin: 0px 0px 30px 0px;
}

.profile-video {
    height: 200px;
    width: 100%;
}

.not-available {
    color: rgb(153, 153, 153);
}

.clear-search-btn-profile {
    position: absolute;
    right: 10px;
    top: 5px;
    z-index: 999999;
    background: white;
    cursor: pointer;
  }

.video-columns-defs {
    font-size: 15px;
    font-weight: 600;
    color: #333333;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #cccccc;
    padding: 10px 0px 10px 0px;
}

.profile-label {
 font-size: 15px;
 color: #333333;
 font-weight: 600;
 width: 20%;
 margin-right: 20px;
}

.profile-data {
    color: #6E6E6E;
    width: 80%;
}

.profile-input {
    border: none;
    border-radius: 5px;
    background: #f3f3f3;
    width: 100%;
}

.profile-display-only {
    background: none;
    border: none;
}


.tabs-row.pr {
    border-bottom: 1px solid #697777;
}

.record-btn.whit-bck {
    background: white !important;
    color: #666666 !important;

}

.profile-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #F2F2F2;
    padding: 10px 0px 20px 0px; 
    margin-bottom: 25px;
    width: 100%;
}

.video-collection-box {

}

.video-row-p {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #697777;
    padding: 5px 0px 0px 0px;
}

.search-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.header-search-profile {
    margin-right: 25px;
}

.tabs-row {
    height: 55px;
    padding: 7px;
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}

.tabs-item {
    margin: 0px 50px 0px 50px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 18px;
    color: #333333;
    cursor: pointer;
}

.video-description-row {
    display: flex;
    flex-direction: row;
}

.article-title-user-vids {
    font-weight: 600;
}