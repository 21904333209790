.results-list {
    height: 700px;
    border: 1px solid #cccccc;
    padding: 0px 7px 7px 7px;
    position: relative;
}

.dashboard-top-row {
    height: 50px;
    padding-top: 20px;
    margin-bottom: 15px;
    color: #666666;
    font-weight: 600;
    display: flex;
    flex-direction: row;

}


.group-by-icon {
    color: #127BC2;
    margin-left: 15px;
    margin-bottom: 3px;
    cursor: pointer;
}

.group-by-row {
    height: 40px;
    border-bottom: 1px dashed #127BC2;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 7px;
}

.results-body {
    overflow-y: auto;
    overflow-x: scroll;
    height: 600px;
    padding: 5px;
}

.top-row-item {
    margin-right: 30px;
    line-height: 36px;
}

.dashboard-panel {
    display: flex;
    flex-direction: row;
    border: 1px solid #cccccc;
    height: 100%;
    padding: 10px;
}

.video-type-header {

    height: 50px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    border-bottom: 1px solid #666666;
    line-height: 50px;
    position: sticky;
    top:0px;
    left: 0px;
    background: aliceblue;
    width: 100%;
    margin-bottom: 5px;
}

.close-modal-btn {
    font-size: 30px;
    background: black;
    color: white;
    border-radius: 10px;
    z-index: 99999;
}


.close-modal {
    position: absolute;
    top: 0px;
    right: 10px;
    width: 70px;
    height: 70px;
    text-align: right;
    z-index: 999;
}

.video-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
    position: relative;
}

.model-video-lbl {
    margin-left: 20px;
}

.toggle-admin-blurb {
    margin-top: 30px;
    text-decoration: underline;
}

.video-lbl {
    text-align: center;
    font-weight: 700;
    font-size: 25px;
}

.factoid {
    margin-right: 25px;
    font-size: 18px;
}

.selected-video-data-item {
    font-size: 18px;
    font-weight: 600;
    margin-left: 25px;
}

.highlight {
    background: #127BC2;
    color: black;
}

.selected-data-row {
    display: flex;
    flex-direction: row;
    padding: 15px;
    border-bottom: 1px solid #666666;
    background: black;
    color: white;
    margin-bottom: 20px;
}

.dashboard-pagination {
    padding-left: 50%;
    padding-right: 40%;
    display: block;
}


.dashboard-pagination li {
    margin: 5px;
}

.dashboard-search-col {
    width: 60%;
    padding: 10px;
}

.admin-video-selected {
    /* width: 100%; */
    max-width: 100%;
    min-width: 50%;
}

.data-display-row {
    display: flex;
    flex-direction: row;
}

.data-display-row.headers {
    border-bottom: 1px solid #cccccc;
    font-weight: 600;
    height: 40px;
    line-height: 30px;
    padding: 5px;
}

.data-display-row.headers:hover {
    background: unset;
}

.display-row-item {
    width: 25%;
}

.data-display-row:hover {
    background: #cccccc;
    cursor: pointer;
}

.article-stats-cont {
    padding: 15px;
    border-radius: 5px;
}

.dashboard-display-col {
    width: 40%;
    padding: 10px;
    border: 1px solid #cccccc;
}

.user-statistics-cont {
    margin-top: 10px;
}

.stats-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    border-bottom: 1px dashed #cccccc;
    padding-bottom: 5px;
}

.flx-stats-wiki {
    flex-direction: row;
    display: flex;
}

.little-row-stats-separator {
    border: 1px dashed #cccccc;
    padding: 5px;
}

.wiki-stats-article-list {
    border: 1px solid gray;
    padding: 5px;
    margin-bottom: 3px;
}

.stats-mini-col {
    margin-right: 25px;
}

.stats-mini-col-2 {
    margin-right: 15px;
    margin-left: 5px;
    border-radius: 5px;
}

.stats-section-title {
    font-size: 25px;
    margin-bottom: 20px;
    margin-top: 25px;
    border-bottom: 1px solid #666666;
}

.stats-col {
    margin-right: 50px;
}

.stats-col.label {
   font-weight: bold;
   width: 200px;
}

.admin-loader {
    text-align: center;
    width: 100%;
    margin-top: 200px;
    font-size: 25px;
}

.admin-video-list {
    width: 25%;
}

.action-div {
    margin: 0 auto;
    margin-bottom: 50px;
}

.admin-action-container {
    text-align: center;
    background: #cccccc;
    border-radius: 15px;
    padding: 25px;
    padding-bottom: 200px;
    margin-top: 25px;
}

.record-btn.big-btn {
    height: 50px;
    width: 500px !important;
}

.group-by-title {
    font-weight: 600;
    font-size: 16px;
    color: #127BC2;
    cursor: pointer;
}

.group-by-detail {
    margin-left: 15px;
}

.filter-by-row {
    display: flex;
    margin-top: 10px;
    flex-direction: row;
    height: 50px;
    padding: 5px;
    justify-content: space-between;
}

.filter-by-btn {
    border: 1px solid #cccccc;
    padding: 2px 7px 2px 7px;
    border-radius: 7px;
    width: 100%;
    margin-right: 5px;
    height: 40px;
}

.filter-by-label {
    padding: 2px 7px 2px 7px;
    border-radius: 7px;
    width: 100%;
    font-weight: 600;
    margin-right: 5px;
    height: 40px;
    line-height: 35px;
}


.filter-by-btn:hover {
    background: #cccccc;
}