.sub-directory {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 15px;
}

.main-title {
    font-size: 22px;
    line-height: 22px;
    display: flex;
    align-items: center;
    /* margin-bottom: 7px; */
}

.page-header {
    display: flex;
    margin-top: 15px;
    flex-direction: row;
    font-family: Segoe UI;
    background: #ccc;
    border-radius: 5px;
    /* position: fixed; */
    /* color: white; */
    background: white;
    /* padding-top: 15px; */
    /* margin-bottom: 10px; */
}

.header-col-1 {
    width: 25%;
    padding-top: 8px;

}
.header-col-2 {
    width: 30%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.header-col-3 {
    padding-top: 10px;
    width: 18%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.header-col-4 {
    width: 2%;
}

.header-col-5 {
    padding-top: 10px;
    width: 25%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.header-search {
    position: relative;
    margin-bottom: 10px;
    width: 100%;
}

.description {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    height: 100%;
    align-items: center;
    display: flex;
}

.section-top-row {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-bottom: 10px;
}

.having-trouble {
    color: #697777;
    font-size: 15px;
    line-height: 20px;
    top: 3px !important;
    text-decoration-line: underline;
    display: flex;
    cursor: pointer;
}

.search-icon {
    position: absolute;
    top: 3px;
    left: 9px;
    z-index: 999;
}

.search-input {
    padding-left: 35px;
    width: 320px;
    height: 47px;
    left: 1030px;
    top: 46px;

    /* Neutrals / White #FFFFFF */

    background: #FFFFFF;
    /* Neutral / 30 */

    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 5px;
}

.toggle-btn {
    cursor: pointer;
  }