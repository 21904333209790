.recorder-controls-row {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.recorder-control {
    margin-right: 20px;
    cursor: pointer;
}

.stop-btn {

}

.record-btn {

}

.re-record-btn {

}

.control {
    color:  #666666;
    border: 1px solid  #666666;
}

.recorder-div {
    text-align: center;
}

.controls {
    display: flex; justify-content: center;
    margin-top: 25px;
}