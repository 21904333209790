/* @font-face {
    font-family: "MWF-MDL2";
    src: url("/fonts/mwf/mwfmdl2-v3.07.woff2") format("woff2"),url("/fonts/mwf/mwfmdl2-v3.07.woff") format("woff"),url("/fonts/mwf/mwfmdl2-v3.07.ttf") format("truetype"),url("/fonts/mwf/mwfmdl2-v3.07.svg") format("svg")
}

@font-face {
    font-family: 'SegoeUI';
    src: local('Segoe UI'),url('/fonts/segoe-ui/west-european/normal/latest.woff2') format("woff2"),url('/fonts/segoe-ui/west-european/normal/latest.woff') format("woff"),url('/fonts/segoe-ui/west-european/normal/latest.ttf') format("ttf");
    font-weight: 400
}

@font-face {
    font-family: 'SegoeUI';
    src: local('Segoe UI'),url('/fonts/segoe-ui/west-european/Light/latest.woff2') format("woff2"),url('/fonts/segoe-ui/west-european/Light/latest.woff') format("woff"),url('/fonts/segoe-ui/west-european/Light/latest.ttf') format("ttf");
    font-weight: 100
}

@font-face {
    font-family: 'SegoeUI';
    src: local('Segoe UI'),url('/fonts/segoe-ui/west-european/Semilight/latest.woff2') format("woff2"),url('/fonts/segoe-ui/west-european/Semilight/latest.woff') format("woff"),url('/fonts/segoe-ui/west-european/Semilight/latest.ttf') format("ttf");
    font-weight: 200
}

@font-face {
    font-family: 'SegoeUI';
    src: local('Segoe UI'),url('srclatest.woff2') format("woff2"),url('src/latest.woff2latest.woff') format("woff"),url('src/latest.woff2latest.ttf') format("ttf");
    font-weight: 600
}

@font-face {
    font-family: 'SegoeUI';
    src: local('Segoe UI'),url('/fonts/segoe-ui/west-european/Bold/latest.woff2') format("woff2"),url('/fonts/segoe-ui/west-european/Bold/latest.woff') format("woff"),url('/fonts/segoe-ui/west-european/Bold/latest.ttf') format("ttf");
    font-weight: 600
} */

body {
    background: white;
    margin: 0;
    padding: 0;
    font-family: "Segoe UI", "SegoeUI", -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
}

.chat-item {
    background-color: #FFFFFF;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #DDDDDD;
}

code {
    font-family: Consolas, source-code-pro, Menlo, Monaco,  "Courier New", monospace;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
}
    .h1, h1 {
        font-size: 2rem;
    }
    .h2, h2 {
        font-size: 1.8rem;
    }
    .h3, h3 {
        font-size: 1.65rem
    }

    .h4, h4 {
        font-size: 1.5rem
    }

    .h5, h5 {
        font-size: 1.3rem
    }

    .h6, h6 {
        font-size: 1.1rem
    }



/* 

@media (min-width: 540px) {
    .h1, h1 {
        font-size: 2.5rem;
    }
    .h2, h2 {
        font-size: 2rem;
    }
    .h3, h3 {
        font-size: 1.75rem
    }

    .h4, h4 {
        font-size: 1.5rem
    }

    .h5, h5 {
        font-size: 1.25rem
    }

    .h6, h6 {
        font-size: 1rem
    }
    .postedDate{
        margin: 0px;
        padding: 0px;
        color:#666F85;
    }
}
 */


.griddle-page-select {
    padding: 3px;
}

.max-h-75 {
    max-height: 75vh !important
}

.max-h-72 {
    max-height: 72vh !important
}

.videoplaceholder {
    height: auto;
    width: 100%;
    max-width:80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
}

.videoplaceholder.verify {
    max-width: 80%;
}

.videoplaceholder.verify video {
    height: 220px;
    width: 295px;
    background-color: #333333;
}

/* @media (min-width: 540px) {
    .videoplaceholder.verify {
        max-width: 100%;
    }
    .videoplaceholder.verify video {
        height: 247px;
        width: 330px;
        background-color: #333333;
    }
    .videoplaceholder {
        max-width: 420px
    }
}
@media (min-width: 992px) {
        .videoplaceholder.verify {
        max-width: 100%;
        width:460px;
    }
    .videoplaceholder.verify video {
        width: 100%;
        height: 345px;
        background-color: #333333;
    }
} */

.btn.disabled, .btn:disabled{
    opacity: 1;
}

.videoplaceholder img,
.videoplaceholder video {
    max-width: 100%;
}


.btn {
    padding: 0px !important;
}

.btn {
    font-weight: 100;
}


.nav-link .person {
    position: relative;
    top: -3px;
}

.nav-link .person .username {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 1rem;
    white-space: nowrap;
    display:none;
}
/*a.userinfo.nav-link {
    position: relative;
    top: 0 !important;
}
*/

.navbar-nav li.nav-item {
    margin: 0 3px;
}

.btn-secondary {
    background-color: white;
}

input:focus {
    border: 1px solid red;
}

.btn-secondary:hover {
    background-color: white;

}

.navbar-nav .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
}



/* .navbar-dark .navbar-toggler:hover, .navbar-dark .navbar-toggler:focus {
    outline-color: transparent;
} */

.navbar-brand {
    font-weight: 600;
    white-space: normal;
    font-size: .9rem;
    margin-right: .55px
}

/* @media (min-width: 1100px) {
    .navbar-brand {
        font-size: 1rem;
    }
} */

a {
    color: #127BC2
}

.light-gray {
    background-color: #f5f5f5
}

.bg-light {
    background-color: #fafafa !important
}

.bg-white {
    background-color: #FFFFFF !important;
}

.btn {
    border-radius: 0;
    padding: .375rem 1.25rem;
    font-weight: 600
}

.btn-primary {
    background-color: #5E1D9F;
    border-color: #5E1D9F;
    font-weight: 600;
    border: 2px solid transparent;
    box-shadow: 0 4px 8px 0 transparent;
    border-radius: 0;
}

.btn-primary:hover {
    background-color: #004896;
    border-color: #004896;
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: #666666;
    border-color: #666666;
}

.btn-primary:focus {
    border-color: #004896;
    box-shadow: none;
    outline: 2px dashed #5E1D9F;
}

.btn-outline-primary {
    color: #5E1D9F;
    border-color: #5E1D9F
}

.btn-outline-primary:hover {
    background-color: #5E1D9F;
    border-color: #5E1D9F
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #5E1D9F;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
    background-color: #5E1D9F;
    border-color: #5E1D9F
}

.btn-link {
    color: #5E1D9F
}

.chatwrapper.p-4 {
    padding:.5rem !important
}

@media (min-width: 540px) {
    .chatwrapper.p-4 {
        padding:1.5rem !important
    }
}

.chat-box .formcontrol {
    border: 1px solid #a4a4a4;
    border-radius: 0
}

.chat-box .username {
    font-size: 1rem !important;
}

.chat-item img.userimg {
    width: 40px;
}

.chat-item .chat-line {
    padding-left: 55px !Important
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #5E1D9F
}

.page-link {
    color: #5E1D9F;
}

.page-item.active .page-link {
    background-color: #5E1D9F;
    border-color: #5E1D9F
}

.badge-primary {
    background-color: #5E1D9F
}

.progress-bar {
    background-color: #5E1D9F;
}

.list-group-item.active {
    background-color: #5E1D9F;
    border-color: #5E1D9F
}

.bg-primary {
    background-color: #5E1D9F !important
}

.border-primary {
    border-color: #5E1D9F !important
}

.text-primary {
    color: #5E1D9F !important
}

.valid-feedback {
    color: #008A00
}

.badge-success {
    background-color: #008A00
}

.bg-success {
    background-color: #008A00 !important
}

.border-success {
    border-color: #008A00 !important
}

.text-success {
    color: #008A00 !important
}

.badge-info {
    color: #fff;
    background-color: #008575
}

.bg-info {
    background-color: #008575 !important
}

.border-info {
    border-color: #008575 !important
}

.text-info {
    color: #008575 !important
}

/* yellow and warning
.btn-warning {
    color: #000000;
    background-color: #ffb900;
    border-color: #ffb900
}

.btn-warning:hover {
    color: #000000;
}

.btn-warning.focus,
.btn-warning:focus {
    box-shadow: 0 0 0 2px rgba(106, 75, 22, 1);
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #000000;
    background-color: #ffb900;
    border-color: #ffb900
}

.btn-outline-warning {
    color: #ffb900;
    border-color: #ffb900
}

.btn-outline-warning:hover {
    color: #000000;
    background-color: #ffb900;
    border-color: #ffb900
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
    box-shadow: 0 0 0 2px rgba(106, 75, 22, 1);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #ffb900;
    background-color: transparent
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
    color: #000000;
    background-color: #ffb900;
    border-color: #ffb900
}

.badge-warning {
    color: #000000;
    background-color: #ffb900
}

.bg-warning {
    background-color: #ffb900 !important
}

.border-warning {
    border-color: #ffb900 !important
}

.text-warning {
    color: #ffb900 !important
} */

.banner .person {
    height: auto !important;
}

.banner .person .h5 {
    font-size: 1.8rem !important;
    font-weight: bold;
}

.banner .userimg, .banner .userscore {
    display: none !important;
}

.pad-left-100 {
    padding-left: 100px;
}

.banner .results p {
    font-size: 1.2rem;
}

/* clip element visibility, making it accessible to screen reader only */
.clipped {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
    white-space: nowrap;
}

img.userimg {
    width: 75px;
}

.nav-link img.userimg {
    width: 40px;
    height: 35px;
}

.nav-link .person {
    padding: 0 !important;
    height: auto !important;
}

.nav-link .person {
    text-align: right;
}

.controls {
    display: block;
    width: 50%;
    margin: 0 auto;
    text-align: center;
}

.record,
.submit {
    margin-left: 15px;
}

.record {
    color: #d83b01;
}

.submit {
    color: green;
}

.hide {
    display: none !important;
}


.verifySignName {
    font-size: 1.3rem;
}

/* @media (min-width: 768px) {
    .nav-link.person {
        text-align: left;
    }

    li.nav-item {
        margin: 0 5px;
    }

    .verifySignName {
        font-size: 1.75rem;
    }
} */

.nav-item {
    margin-right: 15px;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #666666
}

img.userimg {
    max-width: 100%;
    display: inline;
    border-radius: 50%
}

.scoreboard {
    height: 500px;
}

.scoreboard-item {
   padding: 7px;
   border-bottom: 1px solid #ccc; 
}

.scoreboard-item img.userimg {
    width: 40px;
    height:40px;
}

.username, .userscore {
    display: inline;
    margin-left: 15px;
}

.banner .username {
    margin-left: 0
}

.scoreboard-item .userscore {
    font-size: 15px;
}

.scoreboard-item .person {
    padding: 3px 0;
    max-height: 100%;
    max-width: 100%;
}

.height-1rem {
    height: 1rem;
}
.height-125rem {
    height: 1.25rem;
}

.nav-link {
    color:  #666666;
    height: 43px;
    padding-left: 3px;
    padding-right: 3px;
    margin-right: 25px;
}

.controls form {
    width: 100px;
    display: inline;
}

/* @media (min-width: 768px) {
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .navbar-expand-md .navbar-nav .nav-item:last-child .nav-link {
        padding-bottom: 0;
        padding-right: .1rem;
        top: .3rem;
    }
} */

.explore .videoListTable {
    width: 100%
}

.explore .videoListCell {
    padding: 1rem 1.5rem;
    background-color: #FFFFFF;
}

.explore .video-thumb, .explore .myvideo-thumb {
    width: 90%;
    max-width: 380px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    vertical-align: top;
    text-align: center;
}

.explore .video-thumb video, .explore .myvideo-thumb video {
    width: 100%;
    max-width: 250px;
}
.outer-container-r {
    height: 357px;
    width: 100%;
    max-width:80%;
    margin-left: auto;
    margin-right: auto;
}
.inner-container-r {
    display: inline-block;
    position: relative;
}
.video-overlay {
    position: absolute;
    left: 2%;
    z-index: 999;
    top: 0px;
    margin: 10px;
    padding: 5px 5px;
    font-size: 20px;
    font-family: Helvetica;
    color: red;
    background-color: rgba(50, 50, 50, 0.3);
}
/* video {
    width: 100%;
    height: 100%;
} */


.explore .filterdiv {
    padding-bottom: .5rem;
}

.explore .verify-record {
    width: 100%;
    display: block;
    color: #fff;
    position: relative;
    right: 0;
    top: 0;
}

.explore .verify-record > div {
    padding: .4rem 0;
}

.explore p.video-rating,
.explore p.video-author {
    font-weight: 600;
    margin-bottom: .5rem
}

.explore .verify-record .btn {
    width: 140px
}

.explore .video-description {
    width: 100%;
    display: block;
    padding-left: 10px;
}

.explore .list-group-item {
    margin-bottom: 4px;
}

.videosortcontrol .sortarrow {
    height: 1rem;
    width: 1rem;
    float: right;
    margin: 4px;
}



.explore .video-extrainfo {
    margin: 1rem 0 1rem;
    position: relative;
    display: block;
    width:100%;
    max-width: 360px;
}

.explore .video-extrainfo .video-name.h4 {
    width: 96%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 22px !important;
    }

/* 
@media (min-width: 540px) {
    .explore .video-extrainfo .video-name.h4 {
        width: 420px;
        font-size: 22px !important;
        margin-left:auto;
        margin-right:auto;
    }
    .explore .video-description {
        display: table;
        padding-left: 10px;
        width: 100%;
        max-width: auto;
        margin-left:auto;
        margin-right:auto;
    }

    .explore .verify-record {
        width: 20%;
        display: table-cell;
        color: #fff;
        position: relative;
        right: 0;
        top: 0;
    }

    .explore .video-extrainfo {
        margin: 2rem auto;
        position: relative;
        display: block;
        width: 100%;
        max-width: 540px;
        text-align: center;
    }
}

@media (min-width: 768px) {
    .explore .video-extrainfo .video-name.h4 {
    width:450px;
    font-size: 22px !important;
    }
}


@media (min-width: 880px) {
    
    .explore .video-extrainfo .video-name.h4 {
        width: 340px;
        margin-left:0;
        margin-right:auto;
    }

    .explore .video-description {
        display: table-cell;
        padding-left: 50px;
        width: 60%;
        max-width: 330px;
        text-align: left;
    }

    .explore .video-thumb {
        width: 25%;
        min-width: 130px;
        display: table-cell;
        vertical-align: top;
    }

    .explore .myvideo-thumb {
        width: 35%;
        min-width: 130px;
        display: table-cell;
        vertical-align: top;
    }

    .explore .video-extrainfo {
        position: relative;
        display: table-cell;
        width: 80%;
    }

    .explore .verify-record {
        width: 20%;
        display: table-cell;
        color: #fff;
        position: absolute;
        right: 2rem;
        top: 0;
    }
} */

.sentence-wrapper {
    margin-right: 15px;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    padding: 6px;
    line-height: 22px;
}

.read-sentence-wrapper {
    margin-right: 15px;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    padding: 6px;
}

.thumb {
    margin-right: 5px;
    padding: 5px 15px 5px 15px;
    border-radius: 10px;
    color:  white;
    cursor: pointer;
    margin-top: 4px;
    border: none;
}

.vote-box {
    display: flex;
    flex-direction: row;
    padding-top:  5px;
    align-items: center;
    position: relative;
}

.num-thumbs {
    line-height: 33px;
    color:  #666666;
}

.thumb.up {
    margin-left: 25px;
    background: rgba(14, 190, 14,0.4);
}

/* .thumb.up:hover {
    background: rgba(14, 190, 14,1);
} */

/* .thumb.down:hover {
    background: rgba(255, 0, 0,1.0);
} */

.thumb.down {
    margin-left: 25px;
    background: rgba(255, 0, 0,0.4);
}

.nav {
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    box-shadow: 0 2.8px 2.2px rgba(128,128,128, 0.034), 0 6.7px 5.3px rgba(128,128,128, 0.01), 0 8.5px 10px rgba(128,128,128, 0.06), 0 9.3px 17.9px rgba(128,128,128, 0.07), 0 5.8px 7.4px rgba(128,128,128, 0.086), 0 9px 10px rgba(128,128,128, 0.12);

    top: 0px;
    left: 0px;
    height: 45px;
    background: white;
    z-index: 99999;
}


.account-gold {
    height: 20px;
    margin-bottom: 2px;
}

.money-small {
    font-size: 15px;
    margin-left: 15px;
}

.points-nav {
    margin-left: 15px;
}

.score-item {
    margin-top: 6px;
}

.wiki-title-large {
    font-size: 24px;
    text-align: left;
    padding: 5px;
    font-weight: 900;
    color: #5E1D9F;
    font-family: cursive;
    border-bottom: 1px solid #5E1D9F;
    margin-bottom: 15px;

}

.nav-logo {
    color: #127BC2;
    font-weight: 600;
    margin-bottom: 5px;
    cursor: pointer;
}

.nav-contents {
    color: gray;

}

.nav-account {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0px;
}

.profile-clicker {
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid white;
    display: flex;
    flex-direction: row;

    padding: 5px;
    align-items: center;
}

.profile-clicker:hover {
    border: 1px solid #ccc;
    border-radius: 5px;
}


.nav-link.active {
    border-bottom: 2px solid #127BC2;
    height: 43px;
    padding-left: 3px;
    padding-right: 3px;
}

.nav-links {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 15px;
    margin-top: 2px;

}


.btn-secondary:focus {
    background-color: unset !important;
}

.btn-secondary:focus {
    background-color: unset !important;
}

.links {
    display: flex;
    justify-content: flex-end;
}

.profile-image {
    background: white;
    color: #127BC2;
    font-size: 16px;
    font-weight: bold;
    z-index: 9999;
    border: unset !important;
}

.wiki-header-cont {
    border-bottom: 2px solid #F5FDFF;
}

.wiki-header-top {
    border-top: 1px solid #666666 !important;
    margin-bottom: 5px;
    margin-bottom: 15px;
}

.wiki-header-outer {
    border-bottom: 1px solid #666666 !important;
    margin-bottom: 5px;
    margin-bottom: 15px;
}

.wiki-section-box {
    margin-bottom: 20px;
    padding-top: 5px;
}

/* .d {
    color: rgb(16, 153, 252)
} */

.sentence-wrapper:hover {
    cursor: pointer;
} 

.read-sentence-wrapper:hover {
    cursor: pointer;
} 

.sentence-span {
    color: #0EBE0E; position: absolute;
                        line-height: 1.5em;
                        margin-left: 5px;
}

.video-indicator {
    font-size: 10px;
}

.inRead {
    background: red;;
}

.clickity {
    cursor: pointer;
}

.readComplete {
    background: green;
}

.sentence-header-info {
    font-size: calc(1em); font-weight:normal;
    color: #EFFFEF;
}

.sentence-header-title {
    font-size: calc(1.5em);
    color: #333333;
    cursor: pointer;
}

.sentence-header-main-title {
    font-size: calc(1.75em);
    color:  #666666;
    font-weight: bold;
    font-family: sans-serif !important;
}


.wikiHTML {
    /* width: 250px; */
}

/* @media (min-width: 990px) {
    
    .explore .video-extrainfo .video-name.h4 {
        width: 460px;
    }
}

@media (min-width: 1200px) {
    
    .explore .video-extrainfo .video-name.h4 {
        width: 600px;
        max-width: 600px;
    }
} */

.myVideosTable {
    width:100%;
}
.myVideosTable .myInfoRow {
    background-color: rgb(237, 237, 237);
    border: 1px solid rgb(119, 119, 119);
    padding: 5px;
    margin: 10px;
}

.myVideosTable td {
    padding: .5rem .5rem;
    background-color: #FFFFFF;
}
.myVideosTable td:first-child {
    padding-left:1rem;
}
.myVideosTable td:last-child {
    padding-right: 1rem;
}

.myVideosTable th.griddle-table-heading-cell{
    padding:.25rem .5rem
}

.myVideosTable .videoThumbCell img {
    width: 100%;
    max-width: 280px;
    min-width: 60px;
}
.myVideosTable .videoThumbCell videoplaceholder {
    width: 100%;
    max-width: 280px;
    min-width: 60px;
}
.myVideosTable td .h4{
    font-size: 1.1rem;
}
.myVideosTable .myInfoRow .btn {
    padding: .375rem .8rem
}
.myVideosTable .myInfoRow .btn svg {
    display: none !important;
}

.myVideosTable .btn.btn-view {
    margin-right: 0
}

.myVideosTable .btn.btn-view,
.myVideosTable .btn.btn-delete {
    display: block;
    margin: 0 auto .4rem;
    width: 100px;
}

.btn-primary a {
    color: #ffffff
}

.btn-primary a:hover {
    color: #ffffff
}

/* 
@media (min-width: 768px) {
    .myVideosTable .videoThumbCell {
        width: 20%;
        min-width: 120px;
        display: table-cell;
        vertical-align: top;
    }
    .myVideosTable td {
        padding: 1rem .5rem;
    }
    .myVideosTable td:first-child {
        padding-left: 1.5rem;
    }
    .myVideosTable td:last-child {
        padding-right: 1.5rem;
    }
    .myVideosTable .videoThumbCell img {
        max-width: 380px;
    }
    .myVideosTable td .h4 {
        font-size: 1.2rem;
    }
    .myVideosTable .myInfoRow .btn {
        padding: .375rem 1.25rem
    }
    .myVideosTable .myInfoRow .btn svg {
        display: inline-block !important;
    }
    .myVideosTable .btn.btn-view,
    .myVideosTable .btn.btn-delete {
        display:inline-block;
        margin: 0 1rem;
        width:120px;
    }
} */

.userinfo .profiledeets{
    text-align: center;
}

.userinfo .profilephoto{
    width: 100%;
    max-width:140px;
    border-radius: 50%;
    display:block;
    margin: 0 auto 1rem;

}

.table-bordered {
    border: 1px solid #a0a3a5;
}

.table-bordered td,
.table-bordered th {
    border: 1px solid #a0a3a5;
}
.userinfo table.userstatstable{
    margin: .4rem auto;
}
    .userinfo table.userstatstable caption{
        text-align:center;
    }

caption { 
    caption-side: initial;
    color:unset;
    padding-top:.5rem;
    padding-bottom:.5rem; 
} 

/* @media (min-width: 768px) {
    .userinfo .userstats{
        padding-left:8rem;
        padding-top: 0 !important;
    }
    
    .userinfo table.userstatstable{
        margin: .4rem;
    }
    .userinfo table.userstatstable caption{
        text-align:left;
    }
} */

.margin-top{
    margin-top:32px;
}

.minusMargin {
    margin-bottom: -10px;
}

.leftAlign {
    text-align: left;
}

.padding-left{
    padding-left:25px !important;
}

.margin-left{
    margin-left:30px;
}

.signUpConsentForm {
    overflow-y: scroll;
    height:375px;
    resize:none;
    margin : 0 auto;
    width:70%;
    border: 1px solid black;
    padding:0px 5px;
}

.signUpDiv {
    /* margin-left:250px;
    margin-top:75px; */
    width:60%;
    margin: 0 auto;
    margin-top:25px;
}

.margin {
    margin:6px !important;
}

.requiredText {
    margin:30px 0 0 80px;
    font-weight:bold;
}

.requiredTextColor {
    color:#CA1916;
    margin-left: 3px;
}

label.required::after {
    content: '*';
    margin-right: 4px;
    color:#CA1916;
  }

  .btnMarginLeft {
     margin-left:16px;
  }

  .react-datepicker-wrapper {
    margin:0px 0px 12px 15px;
  }
  
div.react-datepicker-wrapper input {
    min-width:250px !important;
    height:36px !important;
}

.dropdown-menu {
    top:6px !important;
    left:-69px !important;
}

.dropdown-item a {
    font-weight:450;
}
.successMsg {
    font-weight: 450;
    margin-top: 10px;
    color: green
}
.errorMsg {
    font-weight: 450;
    margin-top: 10px;
    color:#CA1916;
}

.footerDiv li:after {
    content: '|';
    color: #ccc;
    padding-left: 10px;
}

.footerDiv li:last-child:after {
    content: '|';
    color:white;
    padding-left: 10px;
}

.footerDiv {
    position: fixed;
    bottom:0;
    background-color: white;
    vertical-align: 100%;
    margin-top:15px;
}

.ddnwidth-height {
    min-width: 255px;
    height:36px;
}

.fontWt {
    font-weight:450;
}

.filterControl {
    min-width: 300px;
}

.floatRight {
    float:right;
}

.socialIcons {
    margin: 20px 0 15px 30px;
}

.person .username {
    display:inline-block;
    min-width:120px;
}

.marginBottom {
    margin-bottom:15px;
}

.block-ui-message {
    color:black;
    font-weight:500;
    background-color: white;
    display:inline-block;
    padding:10px 5px 10px 5px;
}

.block-ui-overlay {
    opacity:0.4;
}

.block-ui-message-container {
    max-width: 900px;
    margin: 0 auto;
    top:50%;
}



.Toastify__toast-container--top-right {
    top:5em;
}

.Toastify__toast-body {
    padding:10px;
    font-weight: 700;
    font-size: 18px;
    height: 100px;
}

.emailVerifyText {
    margin-top: 20px;
    font-weight: 500;
}

.socialMediaIcons >a{
   margin:3px;
}

.socialMediaIcons {
    float:right;
    padding:10px 40px 0 0;

}

/* .nav-anchorLink {
    color:#5E1D9F
}

.nav-anchorLink:hover {
    color:#5E1D9F;
    text-decoration:underline;
}
.nav-anchorLink:focus {
    color:#5E1D9F;
    text-decoration:underline;
} */

.signInHeader {
    text-decoration: underline;
}

i.fa.fa-facebook {
    color: white;
    margin-right: 15px;
    font-size: 25px;
}

.my-facebook-button-class {
    background-color: rgb(59, 89, 152);
    height: 50px;
    border: 0px;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px;
    color: rgb(255, 255, 255);
    font-size: 19px;
    margin: 5px;
    width: 350px;
    overflow: hidden;
    padding: 0px 10px;
}

.pointer {
    cursor: pointer;
}

.googleIcon >button {
    border: 0px !important;
    border-radius: 3px !important;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px !important;
    color: rgb(255, 255, 255) !important;
    font-size: 19px !important;
    margin: 5px !important;
    width: 250px !important;
    overflow: hidden !important;
    padding: 0px 10px !important;
    height: 50px !important;
    background-color: rgb(165, 51, 28) !important;
}

.googleIcon span {
    display:none !important;
}

.googleIcon >button >div {
    background-color: rgb(165, 51, 28) !important;
}

.googleIcon >button >div:after {
    content: 'Login with Google' !important;
    margin-left:10px !important;
  }

.mar-left-10 {
    margin-left: -5px;
}

.overflow-y {
    overflow-y: auto !important;
    overflow-x: hidden;
}

