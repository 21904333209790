
.tos-box {
    width: 100%;
}

.page-header {
    text-align: center;
    font-size: 25px;
    margin-bottom: 25px; 
}

.section-header {
    font-size: 18px;
}

.section-text {
    font-size: 12px;
    margin-bottom: 25px;
    margin-top: 15px
}