.vocab-list-directory-header-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 99;
}

.vocab-header-col-1 {
    width: 500px;
}

.tab-list-v {
    background: white;
    height: 40px;
    padding-top: 10px;
    /* margin-right: 25px; */
    padding-left: 25px;
    padding-right: 25px;
    margin-top: 35px;
    font-weight: 600;
    cursor: pointer;
}

.vocab-header-col-2 {
    width: 200px;
    text-align: right;
}

.vocab-header-col-0 {
    width: 500px;
    display: flex;
    flex-direction: row;
}

.create-list-col-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.create-list-col-1 {
    width: 40%;
}

.create-list-col-2 {
    width: 50%;
}

/* track {
    -webkit-transform: rotateY(
360deg
) !important;
} */

/* video {
    -webkit-transform: rotateY(
360deg
) !important;
} */


.vocab-video-grid {
    background: rgb(239, 255, 239);
    padding: 10px;
}

.empty-list-explainer {
    height: 500px;
    text-align: center;
    border: 1px dashed #ccc;
    padding-top: 225px;
}

.vocab-video {
    height: 100px;
}

.list-create-background {
    height: 130px;
    padding-top: 20px;
}

.new-list-creation-title {
    font-size: 25px;
    font-weight: 500;
    color: white;
}

.v-list-title {
    text-align: center;
    font-weight: 600;
    margin-bottom: 15px;
    border-bottom: 1px solid #ccc
}

.vocab-video-row {
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
    margin-bottom: 10px;
    cursor: pointer;
}

.vocab-video-listing-row {
    border-top: 1px solid #CCCCCC;
    cursor: pointer;
}

.vocab-video-listing-row:hover {
    background: rgb(239, 255, 239);
}

.vocab-video-row:hover {
    background: rgb(239, 255, 239);
}

.vocab-video-title-col {
    width: 50%;
    padding: 10px;
    align-items: center;
}

.vocab-plus {
    color: #7a22d1;
}

.vocab-video-col {
    width: 50%;
    padding: 10px;
}

.v-central-item {
    height: 50%;
}

.vocab-list-container {
    margin-top: 30px;
}

.listing-row {
    padding-top: 12px;
    padding-bottom: 5px;
    padding-left: 10px;
    height: 48px;
}